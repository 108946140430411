nav {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100px;

  @include respond-below('md') {
    height: 60px
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($gray-dark, 0.45);
    opacity: 0;
    transition: opacity 600ms;
  }

  .logo {
    max-width: 150px;
    position: absolute;
    top: 32px;
    left: 30px;

    @include respond-below('md') {
      max-width: 100px;
      top: 18px;
      left: 20px;
    }

    img {
      width: 100%;
    }
  }

  // .page-on {
  //   position: absolute;
  //   color: $white;
  //   text-transform: uppercase;
  //   font-family: $font-family-heading;
  //   font-size: 1.75rem;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  // }

  .menu-toggle {
    display: block;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 102;

    @include respond-below('md') {
      top: 20px;
      right: 20px;
    }

    .hamburger {
      height: 16px;
      width: 30px;
      display: flex;
      align-items: center;

      .icon {
        position: relative;
        width: 100%;
        height: 3px;
        background: $white;
        transition: all 200ms;

        &::before {
          content: '';
          position: absolute;
          background: $white;
          top: -9px;
          left: 0;
          width: 100%;
          height: 3px;
        }

        &::after {
          content: '';
          position: absolute;
          background: $white;
          top: 9px;
          left: 0;
          width: 100%;
          height: 3px;
        }

        &::before,
        &::after {
          transition-property: transform, top;
          transition-duration:150ms, 150ms;
          transition-delay:0s, 150ms;
          transition-timing-funtion:linear;
          transform-origin: center center;
        }

        &.close {
          height: 0;
          &::before {
            top: -1px;
            transform: rotate(45deg);
          }
          &::after {
            top: -1px;
            transform: rotate(-45deg);
          }

          &::before,
          &::after {
            transition-property: top, transform;
          }
        }
      }
    }
  }

  .menu-wrapper {
    background: $gray-dark;
    position: fixed;
    visibility: hidden;
    z-index: 100;
    opacity: 0;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    transition: all 300ms;

    .main-menu {
      position: absolute;
      right: 100px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;

      ul {
        @extend .reset;

        li {
          text-align: right;

          a {
            color: $white;
            text-decoration: none;
            font-size: 4rem;
            text-transform: uppercase;
            font-family: $font-family-heading;
          }

          &.active,
          &:hover {
            a {
              color: $green;
            }
          }
        }
      }

      @include respond-below('md') {
        right: 20px;
        ul {
          li {
            a {
              font-size: 3rem;
            }
          }
        }
      }
    }

    &.open {
      opacity: 0.99;
      z-index: 100;
      visibility: visible;

      .main-menu {
        opacity: 1;
      }
    }
  }

  &.on {
    &::before {
      opacity: 1;
    }
  }
}
