.quotes {
  opacity: 0;
  transition: opacity 800ms;

  .wrapper {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    @include grid(1,1,2,2,2, 40px);
    grid-gap: 80px;
    align-items: center;
    justify-content: center;

    > div {
      display: flex;
      flex-flow: column;
      height: 100%;

      border-radius: 20px;
      padding: 40px;

      .image-rounded {
        max-width:100px;
        margin-bottom: 20px;
      }

      p {
        color: $gray-dark;
        font-weight: $font-weight-light;
        font-size: 1.75rem;
        opacity: 0.75;

        i {
          margin: 0 3px;
          font-size: 0.75rem;
        }
      }

      h3 {
        color: $black;
        font-weight: $font-weight-regular;

        &.sub {
          color: $gray-light;
          opacity: 0.6;
        }
      }
    }

    @include respond-below('md') {
      // grid-template-columns: 1fr;

      > div {
        margin-bottom: 20px;

        p {
          font-size: 1.25rem;
        }

        h3 {
          font-size: 1rem;
        }
      }
    }
  }

  &.animate {
    opacity: 1;
  }
}
