// Inputs
// --------------------------------------------------

form{
  width: 100%;
  position: relative;
  margin-bottom:30px;
}

form label{
  display: none;
}

.wpforms-field-hp{
  display: none;
}


// Textfields
input {
  &[type=date],
  &[type=text],
  &[type=email],
  &[type=url],
  &[type=search],
  &[type=password],
  &[type=tel] {
    @extend %input-text;
  }

  border-radius: 6px;
  padding:5px 10px;
  font-size: 18px;
  display: block;
  border: 1px solid #acacac !important;
  margin-bottom:15px;
  width: 100%;
  max-width: 100% !important;
  transition: all 300ms ease;

  &:focus,
  &:active{
    border-color: $green !important;
  }
}

// Selects
select {
  @extend %input-select;
  border-radius: 6px;
  padding:12px 15px !important;
  font-size: 18px;
  display: block;
  border: 1px solid #acacac;
  margin-bottom:15px;
  width: 100%;
  color: #acacac;
  max-width: 100% !important;
  background: url('../images/sort-down-solid.svg');
  background-repeat: no-repeat;
  background-position: 99% 5px;
  background-size:15px;

  &:focus,
  &:active{
    border-color: $green !important;
  }

  option{
    font-size: 18px;
    display: block;
    border: 1px solid #acacac;
    padding:5px 15px !important;
  }
}

// Textarea
textarea{
  border-radius: 6px;
  padding:10px 15px;
  font-size: 16px;
  display: block;
  border: 1px solid #acacac;
  resize: none;
  width: 100%;
  margin-bottom: 15px;
  height:160px;

  &:focus,
  &:active{
    border-color: $green !important;
  }
}

.wpforms-submit {
  @include button;
  @include button-solid;
  box-shadow: none !important;
  outline: none !important;
  color: $white;
}
