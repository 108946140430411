.vacancies {
  ul {
    @extend .reset;

    li {
      padding: 40px 0;
      border-bottom: 1px solid $gray-dark;

      display: grid;
      grid-template-columns: 1fr 300px;

      .text {

      }

      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .share {
          span {
            display: block;
            width: 100%;
            text-align: center;
            margin: 5px 0 10px 0;
            color: $gray;
          }

          i {
            display: inline-block;
            font-size: 1.75rem;
            color: $green;
            margin: 0 5px;
          }
        }
      }

      h2 {
        color: $gray-dark;
        font-size: 4rem;
        text-transform: none;
      }
      h4 {
        color: $gray-dark;
        font-size: 2rem;
        font-weight: $font-family-base;
        margin-bottom: 20px;
      }
      p {
        font-weight: $font-weight-light;
      }
      .button {
        background: $green;
        border: 1px solid $white;

        &:hover {
          background: $white;
          color: $green;
          border: 1px solid $green;
        }
      }
    }
    @include respond-below('md') {
      li{
        display: block;

        h2{
          font-size: 3rem;
        }
      }
    }
  }
}

.values {
  columns: 1;
  margin: 0 auto;

  p {
    font-weight: $font-weight-regular;
  }

  @include respond-below('md') {
    columns:1;
  }
}

.offer {
  ul {
    @extend .reset;

    li {
      font-size: 1.25rem;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 0.3fr 11fr;

      span{
        display: inline-block;
      }

      &::before {
        content: '\f005';
        font-family: 'Font Awesome\ 5 Free';
        color: $green;
        font-weight: 900;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
}

.career-blocks{
  display: grid;
  grid-template-columns: 1fr 1fr;

  h4{
    color: $green;
    font-size:20px; 
  }

  section{
    display: inline-block;

    &.gray-light{
      overflow: visible;

      &:after{
        content:" ";
        left:-100%;
        width:100%;
        height:100%;
        position: absolute;
        background: $gray-lightest;
        top:0;
        z-index: 10;
      }
    }
  }

  @include respond-below('lg') {
    display: block;
    grid-template-columns: 1fr 1fr;
    section{
      display: block;
    }
  }
}
