.numbers {
  ul {
    @extend .reset;
    @include grid(1,1,3,3,3,0);

    li {
      flex: 1;
      text-align: center;
      border-right: 1px solid $gray-dark;
      padding-bottom: 30px;

      div {
        color: $orange;
        font-size: 6rem;
        font-family: $font-family-heading;
        font-weight: $font-weight-bold;

        span {

        }
      }
      h4 {
        font-weight: $font-weight-bold;
        color: $gray-dark;
        font-family: $font-family-base;
        text-transform: none;
        font-size: 1.5rem;
      }

      &:last-child {
        border-right: 0;
        border-bottom: 0;
      }
    }

    @include respond-below ('md') {
      li {
        border-right: 0;
        border-bottom: 1px solid $gray-dark;
      }
    }
  }
}
