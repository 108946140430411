// Reboot
// --------------------------------------------------

::selection {
  color: $selection-color;
  background: $selection-bg;
}

*,
input[type="search"] {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: $font-size-min;

  @include respond-above(md) {
    font-size: ($font-size-min + $font-size-max) / 2;
  }

  @include respond-above(lg) {
    font-size: $font-size-max;
  }
}
*::-webkit-media-controls-start-playback-button {
    display: none !important;
}

body {
  background: $body-bg;
  color: $body-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: $link-color;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $white;
  margin: 0;
}

h1 {
  font-size: 80px;
  line-height: 1.15;

  @include respond-below('xl') {
    font-size: 60px;
  }

  @include respond-below('sm') {
    font-size: 40px;
  }

  @media screen and (max-height: 600px) {
    font-size: 40px !important;
  }
}

h2 {
  font-size: 32px;

  @include respond-below('sm') {
    font-size: 28px;
  }
}

h3 {
  font-size: 20px;

  @include respond-below('sm') {
    font-size: 18px;
  }
}

p {
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 1.25rem;

  @include respond-below('sm') {
    // font-size: 1rem;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

embed,
img,
object,
video {
  display: block;
  max-width: 100%;
  height: auto;
}
