footer{
  ul{
    li{
      a{
        &:hover{
          color: $green;
        }
      }
    }
  }
}
