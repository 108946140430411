.partners {
  h1 {
    color: $black;
  }

  ul {
    list-style: none;
    display: grid;
    @include grid(2,2,4,4,4, 80px);
    grid-gap: 80px;
    align-items: center;
    margin-top: 80px;
    padding: 0;
    transform: translateX(100%);
    opacity: 0;

    li {
      a {
        img {
          margin: 0 auto;
        }
      }
    }

    @keyframes animatelogos {
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    // @include respond-below('sm') {
    //     grid-template-columns: 1fr 1fr;
    // }
  }

  &.animate {
    ul {
      animation-name: animatelogos;
      animation-duration: 800ms;
      animation-fill-mode: forwards;
    }
  }
}
