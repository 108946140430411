.services {
  .wrapper {
    ul {
      @extend .reset;
      max-width: 900px;
      text-align: center;
      margin: 0 auto;
      margin-top:50px;
      transform: translateY(0);
      transition: all 400ms;

      li {
        display: inline-block;
        margin: 15px 5px;

        .tag {
          color: $gray-dark;
          padding: 10px 20px;
          background: $white;
          box-shadow: 0px 0px 10px rgba($gray-dark, 0.5);
          border-radius: 6px;
          text-decoration: none;
          text-transform: uppercase;
          font-family: $font-family-heading;
          font-weight: $font-weight-regular;
          font-size: 1.5rem;
          transition: all 400ms;
          cursor: pointer;

          &:hover {
            background: $green;
            color: $white;
          }
        }

        .cloud-text{
          opacity: 0;
          visibility: hidden;
          display: inline-block;
          position: absolute;
          top:-180px;
          left:0;
          right:0;
          margin:0 auto;
          width: 550px;
          max-width: 100%;
          text-align: center;
          // font-weight: bold;
          font-size: 22px;
          transition: all 400ms;
          transform: translateX(-100%);
        }

        &.active{
          .cloud-text{
            opacity: 1;
            visibility: visible;
            transform: translateX(0%);
          }

          .tag{
            background: $green;
            color: $white;
          }
        }
      }

      &.active{
        transform: translateY(200px);
        padding-bottom: 250px;
      }
    }

    @include respond-below ('md') {
      ul{
        transform: translateY(-20px);

        li{
          &.active{
            .cloud-text{
              margin-top:-90px;
            }
          }
        }
      }
    }
  }
}

#what-we-do{
  li {
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 10fr;
    }

    display: flex;

    > div {
      padding-right: 10px;
      display: inline-block;
    }
  }
}
