.box-shadow {
  box-shadow: 0px 3px 10px 3px rgba($gray-lighter, 0.4);
}

.text-shadow {
  text-shadow: 2px 4px 3px rgba($gray-dark, 0.3);
}

.button.read-more {
  border: 1px solid $gray-light;
  font-weight: $font-weight-bold;

  &:hover {
    border: 1px solid $gray-light;
  }
}

.centered {
  margin: 0 auto;
  text-align: center;
  display: block;
}

.centered-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-rounded {
  display: inline-block;

  img {
    border-radius: 100%;
  }
}

.delimiter {
  width: 25px;
  height: 3px;
  background: $white;
  margin: 20px 0 40px 0;

  &.dark {
    background: $gray-dark;
  }

  @include respond-below ('md') {
    margin: 10px 0;
  }
}

#top-bar {
  background: $green;
  padding: 50px 0;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hide-for-small {
  @include respond-below('sm') {
    display: none;
  }
}

#layout-container {
  &.green, .green { background: $green; }
  &.purple, .purple { background: $purple; }
  &.blue, .blue { background: $blue; }
  &.orange, .orange { background: $orange; }
  &.gray, .gray { background: $gray-dark; }
  &.white, .white { background: $white; }
  .gray-light { background: $gray-lightest; }
  .text-green { color: $green !important; }
  // .text-red { color: $red !important; }
  .text-blue { color: $blue !important; }
  .text-purple { color: $purple !important; }
  .text-orange { color: $orange !important; }
}
