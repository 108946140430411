.carousel {
  background: $white;

  .wrapper {
    // max-width: 100%;
    @include grid(1, 1, 2, 2, 2, 40px);

    > div {
      position: relative;
    }

    h1 {
      color: $gray-dark;
    }

    .carousel-items {
      a {
        position: absolute;
        z-index: 10;
        left: 50%;
        bottom: 100px;
        opacity: 0.5;
        transition: left 1.2s, transform 1.2s, bottom 1.2s, opacity 500ms;

        img {
          max-width: 180px;
          transform-origin: center bottom;
          transition: transform 1.2s;
        }

        &.active-item {
          z-index: 11;
          opacity: 1;
          transform: translateX(-50%);
          bottom: 20px;

          img {
            transform: scale(1.3);
          }
        }

        &.wide {
          img {
            max-width: 320px;
          }
        }

        &.left {
          left: 0;
          right: auto;
        }
        &.right {
          left: 100%;
          transform: translateX(-100%);
        }
      }
    }

    ul {
      list-style: none;
      display: inline-block;
      margin: 100px 0;
      padding: 0;

      li {
        width: 100%;
        float: left;

        h2 {
          font-size: 3rem;
          transition: all 1s;
          a {
            position: relative;
            text-decoration: none;
            color: $green;
          }
        }

        &.active h2 {
          transform: translateX(40px);
        }
      }
    }
  }
}
