.streamer {
  opacity: 0;
  transform: translateX(-200%);
  transition: all 800ms;

  h3 {
     color: black;
  }

  h1 {
    color: $gray-dark;
    letter-spacing: -2px;
    font-size: 6rem;
    line-height: 1.2;
    font-weight: $font-weight-bold;

    @include respond-below('sm') {
      font-size: 42px;
    }
  }

  &.animate {
    transform: translateX(0);
    opacity: 1;
  }
}
