// Typography
// --------------------------------------------------

$font-family-base:    'Roboto', sans-serif;
$font-family-heading: 'Oswald', sans-serif;

$font-weight-light:   300;
$font-weight-regular: 400;
$font-weight-bold:    700;
$font-weight-black:   900;

$font-size-min:       14px;
$font-size-max:       16px;
$font-size-base:      1rem;
$font-size-lg:        1.25rem;
$font-size-sm:        0.875rem;
$font-size-xs:        0.75rem;

$line-height:         1.5;

$grid-gap:            40px;


// Colors
// --------------------------------------------------

$white:               #fff;
$black:               #000000;
$off-white:           #F5F5F5;
$green:               #AFC530;
$blue:                #0da6cf;
$purple:              #683FF1;

// Client colors
$orange:              #ed9600;

// Alternative colors
$yellow:              #FEE47B;
$orange:              #fec426;
$blue:                 #1e1e51;

$gray-darker:         #1B1E24;
$gray-dark:           #2E2E2E;
$gray:                #8B8B8B;
$gray-light:          #787878;
$gray-lighter:        #D8D8D8;
$gray-lightest:       #F7F7F7;

$body-bg:             $white;
$body-color:          $gray-dark;
$selection-bg:        $green;
$selection-color:     $white;
$link-color:          $green;
$link-hover-color:    $blue;

$primary-color:       $green;
$secondary-color:     $blue;

// Buttons
// --------------------------------------------------

$button-bg:           $white;
$button-hover-bg:     $white;
$button-color:        $black;


// Grid, Breakpoints & Widths
// --------------------------------------------------

$site-width:          100%;
$site-min-width:      320px;
$site-max-width:      1400px;

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1281px,
  xxl: 1440px,
);
