.big-title {
  .wrapper {
    padding:0 20px;
    > h2 {
      color: $gray-dark;
      font-weight: $font-weight-regular;
      font-size: 3.5rem;
      text-align: center;
      position: relative;
      margin-bottom: 100px;
      z-index: 2;

      span {
        color: $gray-lighter;
        font-size: 2rem;
        position: absolute;
        left: 0;
        right:0;
        margin:0 auto;
        display: block;
        top: -35px;
        z-index: 3;
        &:before{
          content:" ";
          display: block;
          height:2px;
          width:60px;
          left:0;
          right:100px;
          background: $gray-lighter;
          position: absolute;
          top:25px;
          margin:0 auto;
          z-index: 0;
        }
        &:after{
          content:" ";
          display: block;
          height:2px;
          width:60px;
          left:100px;
          right:0px;
          background: $gray-lighter;
          position: absolute;
          top:25px;
          margin:0 auto;
          z-index: 0;
        }
      }
    }

    > p {
      text-align: center;
      font-weight: $font-weight-light;
      max-width: 800px;
      margin: 0 auto;
    }

    .icon-list {
      @extend .reset;

      li {
        text-align: left;
        width: 100%;
        padding-bottom: 40px;
        display: flex;

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 1fr 11fr;
        }

        > div {
          flex: 1;
          padding-right: 20px;

          &.text {
            flex: 11;
          }
        }

        div{
          margin-right:10px;
        }

        svg {
          margin-top:15px;
          text-align: center;
          margin-right: 15px;

          path {
            fill: $green;
          }
        }

        &:nth-child(3n+ 2) {
          svg {
            path {
              fill: $orange;
            }
          }
        }

        &:nth-child(3n+ 3) {
          svg {
            path {
              fill: $blue;
            }
          }
        }

        h3 {
          color: $gray-dark;
          font-weight: $font-weight-light;
          font-size: 2rem;
        }

        p {
          text-align: left;
          max-width: 100%;
        }

        @include respond-below('md') {
          svg{
            width: 40px;
          }
        }
      }
    }
  }

  &.gray-light {
    h2 {
      span {
        color: $gray;
      }
    }
  }
  &.image {

    li {

      div{
        margin-right:15px;
      }
      svg {
        path {
          fill: $white;
        }
      }
    }

    h2 {
      color: $white;
    }

    ul{
      h2{
        font-size: 2.5em;
      }

      li{
        font-size: 2em !important;
      }
    }
  }
}

@include respond-below('md') {
  .big-title {
    padding-top:100px;
  }
}
