.portfolio-item {
  overflow-x: hidden;

  .overlay-intro {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 40px;
    }

    .image {
      flex: 1;
    }

    .portfolio-content {
      flex: 2;
    }

    h2 {
      opacity: 0.6;
      font-weight: $font-weight-regular;
    }

    &.reverse {
      @include grid(2,2,2,2,2);
    }
    img {
      padding: 0 40px;
    }

    &.overflow {
      .image {
          position: relative;
      }
      img {
        position: absolute;
        max-width: 175%;
        padding: 0;
        right: 0;
      }
      &.reverse {
        .portfolio-content {
          grid-column: 1 / 2;
        }
        .image {
          grid-column: 2 / 3; 
          transform: translateX(200%);

          img {
            left: 0;
            right: auto;
          }
        }
      }
    }

    .portfolio-content {
      display: flex;
      flex-direction: column;

      transition: all 800ms ease;

      > * {
        transform: translateY(-50px);
        opacity: 0;
      }

      &.slide-down {
        > * {
          animation-name: micro-animation;
          animation-duration: 800ms;
          animation-fill-mode: forwards;

          &:nth-child(1){ animation-delay: 150ms; }
          &:nth-child(2){ animation-delay: 125ms; }
          &:nth-child(3){ animation-delay: 100ms; }
          &:nth-child(4){ animation-delay: 75ms; }
          &:nth-child(5){ animation-delay: 50ms; }
          &:nth-child(6){ animation-delay: 25ms; }
        }
      }

      //Responsive portfolio content
      @include respond-below('lg') {
        width: 100%;
        max-width: 100%;
        padding-left: 30px !important;
      }
      @include respond-below('lg') {
        padding-left: 0px !important;
      }
    }

    .image {
      opacity: 0;
      transform: translateX(-200%);
      transition: all 800ms ease;

      ul {
        @extend .reset;

        li {
          width: 100%;
          display: inline-block;

          > div {
            width: 50%;
            position: relative;

            span {
              position: absolute;
              color: $white;
              text-transform: uppercase;
              font-family: $font-family-heading;
              font-size: 1.5rem;
              top: 50%;
              left: 0;
              transform: translate(-40px, -50%);
            }

            .rounded {
              border-radius: 9px;
              overflow: hidden;
              position: relative;
              display: inline-block;

              img {
                padding: 0;
                max-height: 150px;
              }

              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($purple, 0.65);
              }
            }
          }

          &:nth-child(odd) {
            > div {
              margin-left: 35%;
            }
          }

          &:nth-child(2) {
            > div {
              transform: translateY(-10%);
            }
          }

          &:nth-child(3) {
            > div {
              transform: translateY(-20%);
            }
          }
        }
      }

      &.slide-in {
        opacity: 1;
        transform: translateX(0) !important;
      }

      //Responsive image
      @include respond-below('lg') {
        margin:-30px auto;
        position: relative;

        img{
          position: relative;
          transform: translateY(0%) !important;
          margin-bottom: 50px;
          width: auto;
          height: auto;
          max-height: 450px;
          margin:0 auto 50px auto;
        }
      }
    }

    @include respond-below('lg') {
      grid-template-columns: 1fr;
      grid-gap: 10px;

      .portfolio-content {
        p {
          font-size: 1.25rem;
        }

        .numbers {
          padding-bottom: 0;
          > div {

            border-right: 0;
            // display: inline-block;
            // width: 100%;

            .number {
              font-size: 2rem;
            }
            &:first-child {
              border-right: 1px solid $white;
            }
            &:nth-child(3) {
              display: none;
            }
          }
        }
      }

      .image {
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        img {
          max-width: 250px;
          margin: 0 auto;
        }
      }

      &.reverse {
        grid-template-columns: 1fr;

        .image {
          grid-column: 1;
          grid-row: 1;
        }
      }

      &.overflow {
        .image {
          margin-bottom: 20px;

          img {
            position: relative;
            max-height: none;
            max-width: 240px;
            transform: translateX(-40%);
          }
        }
        &.reverse {
          .image {
            grid-column: 1;
            grid-row: 1;
            img {
              transform: translateX(40%);
            }
          }
        }
      }
    }

    .build-items{
      .build-item{
        display: flex;
        flex-flow: row wrap;
        margin:35px 0;

        &:first-child{
          margin-top:0;
        }

        @supports (display: grid) {
          display: grid;
          grid-template-columns: 2fr 10fr;
          grid-gap: 0px;
        }

        .icon {
          flex: 1;
          margin-top:25px;

          img{
            height:60px;
            text-align:right;
          }
        }

        .text {
          flex: 5;

          p{
            font-size:18px;
            line-height: 1.5;
          }
        }
      }

      @include respond-below('lg') {
        .build-item{
          text-align: left;
          .icon {
            img{
              height:60px;
              margin:15px 0 0 0;
            }
          }
        }
      }

      @include respond-below('md') {
        .build-item{
          .icon {
            img{
              height:auto;
              max-height: 70px;
              width:140px;
            }
          }
        }
      }
    }

    //Responsive overlay intro
    @include respond-below('lg') {
      display: block;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0px;
    }
  }

  &.wwd { 

    .overlay-intro {
      .image {
        flex: 2;
      }

      .portfolio-content {
        flex: 1;
      }
    }
  }

  h1 {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
  }
  p {
    font-size: 1.5rem;
    opacity: 0.85;
  }
  a {
    border: 2px solid $white;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .numbers {
    margin-top: auto;
    padding-bottom: 60px;

    > div {
      display: inline-block;
      color: $white;
      border-right: 1px solid $white;
      font-size: 1.5rem;
      margin-right: 10px;
      padding-right: 10px;

      .number {
        font-size: 3rem;
        display: inline-block;
        font-family: $font-family-heading;

        > div {
          height: auto;
          font-size: 4rem;
          display: inline-block;
        }
      }

      .text {
        font-weight: $font-weight-light;
        display: table;
      }

      &:last-child {
        border: none;
      }
    }

    @include respond-below('sm') {
      grid-template-columns: 1fr 1fr;
      justify-content: center;

      > div {
        border: none;
        font-size: 1.25rem;

        .number {
          font-size: 3rem;
        }

        &:nth-child(odd) {
          border-right: 1px solid $white;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}

@keyframes micro-animation {
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#work {
  padding: 0;
  background: $gray-dark;

  .work-right-block{
    position: absolute;
    right: -50%;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 1000;
  }

  > .wrapper {
    // display: flex;
    // flex-direction: row;
    // @supports (display: grid) {
    //   display: grid;
    //   grid-template-columns: 300px auto;
    // }

    // .work-nav {
    //   background: $gray-dark;
    //   flex: 0 1 300px;
    //
    //   ul {
    //     @extend .reset;
    //
    //     li {
    //       display: inline-block;
    //       width: calc(100% - 20px);
    //       margin-left: 20px;
    //       border-bottom: 2px solid $gray-darker;
    //
    //       a {
    //         padding: 15px 20px;
    //         display: block;
    //         text-decoration: none;
    //         font-family: $font-family-heading;
    //         font-weight: $font-weight-bold;
    //         line-height: 1;
    //         overflow: hidden;
    //         position: relative;
    //         width: 100%;
    //         cursor: pointer;
    //         color: $white;
    //
    //         span {
    //           font-family: $font-family-base;
    //           font-weight: $font-weight-regular;
    //           font-size: 0.75rem;
    //           display: inline-block;
    //           width: 100%;
    //         }
    //       }
    //
    //       a {
    //         &::after {
    //           content: ' ';
    //           position: absolute;
    //           width: 50px;
    //           height: 50px;
    //           right: -25px;
    //           bottom: -25px;
    //           background: var(--color);
    //           opacity: 0;
    //           transition: all 500ms ease;
    //           transform: translateX(+100%);
    //           transition-delay: 200ms;
    //         }
    //       }
    //
    //       &.active {
    //         a {
    //           &::after {
    //             opacity: 1;
    //             transform: translateX(-0%);
    //             transform-origin: center center;
    //             transform: rotate(45deg);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .portfolio_items {
    position: relative;
    transition: all 800ms ease;
    flex: 1 0 auto;

    .portfolio-item {
      // position: absolute;
      // visibility: hidden;
      // opacity: 0;
      // z-index: 0;
      // overflow: visible !important;
      // transition: all 500ms ease;

      // .wrapper {
      //   max-width: none;
      // }

      &.active {
        position: absolute;
        opacity: 1;
        visibility: visible;
      }

      .overlay-intro {
        grid-gap: 0;

        &.overflow {
          .portfolio-content {
            padding-left: 100px;
          }

          .image {

            img {
              max-width: 700px;
              width: 100%;
              transform: translateY(50%);
            }
          }
        }
      }
    }
  }
}

/* Portfolio detail page */
.project-detail {
  .work-header {
    height: 100vh;
    min-height: 550px;
    background-size: cover;
    background-position: center;

    .overlay{
      position: absolute;
      z-index: 1;
      background: rgba($gray-darker, 0.30);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; 
      height: 100%;
      width: 100%;
    }

    .wrapper {
      position: relative;
      height: 100%;

      .content {
        position: absolute;
        max-width: 60%;
        bottom: 100px;
        z-index: 2;

        p {
          display: block;
          margin: 30px 0;
          color: $white;
        }

        a {
          font-size: 1.4rem;
          font-weight: $font-weight-bold;
          text-decoration: none;
          color: $white;

          img {
            display: inline-block;
            border: 2px solid $white;
            padding: 12px 10px;
            box-sizing: border-box;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }

        @include respond-below(md){
          max-width: 90%; 
        }
      }
    }
  }

  .text {
    padding: 100px 0;
    color: $gray-darker;
    background: $off-white;

    h4 {
      color: $gray-darker;
    }

    .wrapper {
      .text-one {
        width: calc(60% - 50px);
        display: inline-block;
        margin-right: 55px;

        h4 {
          margin-bottom: 20px;
        }

        p {
          color: $gray;
        }

        @include respond-below(md){
          display: block;
          width: 100%; 
        }
      }

      .text-two {
        width: calc(40% - 15px);
        display: inline-block;
        vertical-align: top;

        ul {
          @extend .reset;

          li {
            margin-bottom: 15px;

            h4:last-child {
              color: $gray;
            }
          }
        }
        @include respond-below(md){
          display: block;
          width: 100%; 
        }
      }
    }
    @include respond-below(md){
      padding: 50px 0;
    }
  }

  .branding {
    position: relative;
    height: 100%;

    .img {
      width: calc(50% - 20px);
      display: inline-block;
      text-align: center;
      padding: 50px 0;
      vertical-align: middle;

      img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
        max-height: 400px;
        height: auto;
      }

      @include respond-below(md){
        display: block;
        width: 100%; 
      }
    }

    .colors {
      width: calc(50% - 20px);
      display: inline-block;
      vertical-align: middle;
      position: relative;
      height: 600px;

      div {
        height: 100%;
        width: 30%;
        float: right;
        display: inline-block;
      }

      @include respond-below(md){
        display: block;
        width: 100%; 
        height: auto;
        padding: 50px;

        div {
          float:none;
          width: 100%;
          height: 80px;
        }
      }
    }
  }

  .big-image {
    img {
      width: 100%;
    }
  }
  .clear {
    clear: both;
  }
  .images {
    padding: 60px 0;
    .wrapper {
      text-align: center;

      img {
        width: calc(50% - 20px);
        display: inline-block;  
        margin-bottom: 15px;

        &:nth-child(even){
          float: right;
        }

        &:nth-child(odd){
          float: left;
        }

        &:first-child {
          display: block;
          width: 100%;
          float: none;
        }

        @include respond-below(md){
          display: block;
          width: 100%; 
        }
      }
    }
  }

  .text-block {
    padding: 150px 0;
    background: $off-white;
    text-align: center;
  }

  .slider-wrapper {
    padding: 50px 0;

    .sliderBtn {
      display: inline-block;
      border: 2px solid $green;
      background-color: $green;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      padding: 12px 10px;
      box-sizing: border-box;
      border-radius: 100%;
      width: 50px;
      box-shadow: 0px 10px 20px rgba($gray, 0.30);
      height: 50px;
      vertical-align: middle;
      margin-right: 10px;
      text-align: center;
      cursor: pointer;

      &:before {
        font-family: "Font Awesome 5 Free"; 
        font-weight: 900; 
        content: "\f054";
        font-size: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        color: $white;
      }
    }
  
    .prevButton {
      left: 20px;

      &:before {
        content: "\f053";
      }
    }
  
    .nextButton {
      right: 20px;
    }
  
    .carousel-item {
      height: 500px;
  
      > div {
        height: 100%;
        width: 100%;
        position: relative;
        background-size: cover; 
        background-position: center;
      }

      @include respond-below(md){
        height: 350px;
      }

      @include respond-above(lg){
        height: 650px;
      }
    }
  }
}
