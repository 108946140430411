.full-header {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  background-size: cover;
  background-position: center;

  // @include respond-below('sm') {
  //   height: 70vh;
  // }

  .video-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    min-width: 100vw;
    height: 100vh;

    video {
      position: absolute;
      width: auto;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      background: rgba($gray-dark, 0.6);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;

    }
  }

  .overlay-intro {
    position: absolute;
    padding: 0 100px;

    &.fadein {
      animation-name: fadeIn;
      animation-duration: 3200ms;
      animation-fill-mode: forwards;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 0;
    }

    a {
      margin-top: 40px;
      background: $green;
    }

    span {
      color: $green;
    }

    @include respond-above('xl') {
      h1 {
        font-size: 7.5rem;
        line-height: 1;
      }
    }

    @include respond-below('xl') {
      padding: 0 20px !important;

      p {
        font-size: 1.25rem;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &.image {
    height: 75vh;
    .image-wrapper {
      height: 100%;

      .overlay-intro {
        position: relative;
        padding: 0;

        p {
          max-width: 50%;

          @include respond-below('md') {
            max-width: 100%;
          }
        }

        span {
          color: $green;
        }
      }
    }

    @media screen and (max-height: 700px) {
      height: 100vh;
    }
  }

  &.lists {
    height: auto;
    padding: 80px 0;

    .overlay-intro {
      @include grid (1,1,2,2,2);
      padding: 0;

      ul {
        @extend .reset;

        h2 {
          color: $primary-color;
          font-weight: $font-weight-regular;
        }

        li {
          color: $white;
          padding: 40px 0;
          font-size: 2.5rem;
          text-transform: uppercase;
          font-family: $font-family-heading;
        }
      }
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    background: rgba($gray-dark, 0.60);
  }
}
