// Buttons
// --------------------------------------------------

.button {
  @include button;
  color: $white;

  &.ghost {
    background: transparent;
    border-color: $white;

    &:hover {
      background: $white;
      color: $gray;
      border-color: $white; 
    }
  }
}

.button-solid {
  @include button-solid;
  color: $white;
}
