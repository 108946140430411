// Layout
// --------------------------------------------------

#layout-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin: 0;
}

section {
  position: relative;
  padding: 80px 20px;
  width: 100%;
  overflow: hidden;

  @include respond-below('sm') {
    padding: 40px 0;
  }
}

.wrapper {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    position: relative;
    display: block;

    @include respond-below('lg') {
      padding: 0 20px;
    }

    @include respond-above('xxl') {
      max-width: 1440px;
    }
}

.overlay-intro {
  height: 100%;

  h1 {
    text-transform: uppercase;
    color: $white;

    &#fliphome {
      -vendor-animation-duration: 30s;
      -vendor-animation-delay: 2s;
      -vendor-animation-iteration-count: infinite;
    }
  }

  p {
    color: $white;
  }

  a.button {
    background: transparent;
    color: $white;
  }
}
