footer {
  background: $gray-dark;
  padding-bottom: 0;
  padding-top: 80px;
  text-align: center;

  img {
    max-width: 340px;
    display: inline-block;
  }

  .delimiter {
    margin: 0 auto;
  }

  ul {
    margin: 40px auto 60px auto;
    @include grid(1,1,3,3,3);
    list-style: none;
    max-width: 1000px;
    padding: 0;

    li {
      padding: 0 20px;
      text-align: left;
      font-size: 1.75rem;
      display: flex;
      align-items: center;

      i {
        color: $green;
        float: left;
        display: block;
        width: 60px;
      }
      a {
        color: $gray;
        font-size: 1rem;
        text-decoration: none;
        margin: 0;
        float: left;
        width: 100%;
      }

      p {
        color: $gray;
        font-size: 1rem;
        margin: 0;

      }
    }
  }

  .bottom-bar {
    background: $black;
    padding: 30px 0;

    p {
      font-size: 0.875rem;
      color: $gray;
      margin:0;
    }
  }

  @include respond-below('sm') {
    padding-top: 40px;

    img {
      max-width: 200px;
    }
    ul {
      grid-template-columns: 1fr;
      margin: 20px 0;

      li {
        text-align: left;
        align-items:flex-start;
        margin-bottom: 20px;
        width: auto;

        i {
          text-align: center;
          min-width: 100px;
        }
      }
    }
  }
}
