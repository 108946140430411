.contact-footer {
  padding: 0;
  // background: linear-gradient(to right, $white 66.66%, $green 66.66%);

  .wrapper {
    // background: linear-gradient(to right, $white 66.66%, $green 66.66%);
    display: flex;
    flex-flow: row wrap;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }

    a{
      color: $white;

      &:hover{
        opacity: 0.70;
      }
    }

    .form {
      flex: 2;
      padding: 40px;
      padding-left: 0;

      h2 {
        color: $gray-dark;
        margin: 40px 0;
      }

      div.wpforms-container-full .wpforms-form {
        max-width: 500px;

        input,
        textarea {
          border: none;
          border-bottom: 1px solid $gray-lighter;
          max-width: 100%;

          &::placeholder{
            color: $gray-lighter;
          }
        }
        .wpforms-submit {
          background: $primary-color;
          color: $white;
          border: 0;
          border-radius: 5px;
          font-weight: 500;
          font-size: 1.25rem;
          font-family: 'Oswald';
          padding: 0.75em 1.5em;
          text-transform: uppercase;
        }
      }
    }
    .info {
      flex: 1;
      .info-inner {
        padding: 40px;

        p {
          color: $white;
          font-size: 1rem;

          a {
            text-decoration: none;
          }
        }
      }
    }

    @include respond-below ('md') {
      @supports(display: grid) {
        grid-template-columns: 1fr;
      }
      padding-bottom: 20px;

      .form {
        flex :1;
        padding: 0;
      }

    }
  }
}
